<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CustomerStageTablePopup v-if="pop.isShowStep" @close="pop.isShowStep=false"></CustomerStageTablePopup>
        <DoubleCheck1Popup :companyname="pop.cname" v-model="pop.isAble" v-if="pop.isShowCheck" v-on:close="pop.hideCheck"></DoubleCheck1Popup>

        <CarrotTitle title="고객사등록">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default" @click="pop.showStep">고객사 단계 구분표</button>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사명</th>
                                <td>
                                    <input type="text" v-model.trim="add.cname" class="w-300px float-left mr-5" maxlength="50">
                                    <button class="btn-default float-left h-30px" @click="pop.showCheck">중복확인</button>
                                    <span class="ml-10">* 공백 없이 한글만 입력해주세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 영문명</th>
                                <td>
                                    <input type="text" v-model.trim="add.ename" class="w-300px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 주소</th>
                                <td>
                                    <carrot-address v-model:zipcode.sync="add.zipcode" v-model:address.sync="add.addr"></carrot-address>
                                    <input type="text" v-model.trim="add.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th>대표자명</th>
                                <td>
                                    <input type="text" v-model.trim="add.ceo_name" class="w-200px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th>사업자번호</th>
                                <td>
                                    <input type="text" v-model="add.bizno" class="w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>고객사 단계</th>
                                <td>
                                    <carrot-company-step v-model="add.step" class="w-200px"></carrot-company-step>
                                    <!-- <select class="w-200px">
                                        <option value="">선택</option>
                                    </select> -->
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 유형</th>
                                <td>
                                    <carrot-company-type v-model="add.company_type" class="w-200px"></carrot-company-type>
                                    <!-- <select class="w-200px">
                                        <option value="">선택</option>
                                    </select> -->
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 산업군</th>
                                <td>
                                    <carrot-industry v-model="add.industry_type" class="w-200px"></carrot-industry>
                                    <!-- <select class="w-200px">
                                        <option value="">선택</option>
                                    </select> -->
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 특이사항</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="add.special_memo"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="add.doCancel">취소</button>
                    <button class="btn-default float-right mt-30" @click="add.doSubmit">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotCompanyStep from '@/components/common/CarrotCompanyStep.vue'
import CarrotCompanyType from '@/components/common/CarrotCompanyType.vue'
import CarrotIndustry from '@/components/common/CarrotIndustry.vue'
import CustomerStageTablePopup from '@/components/popup/customerManagement/CustomerStageTablePopup.vue'
import DoubleCheck1Popup from '@/components/popup/customerManagement/DoubleCheck1Popup.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotAddress,
        CarrotCompanyStep,
        CarrotCompanyType,
        CarrotIndustry,
        CustomerStageTablePopup, 
        DoubleCheck1Popup
    },
    setup() {
        const router = new useRouter();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            cname : "",
            isAble : "",
            isShowStep : false,
            isShowCheck: false,

            showStep : () => {
                pop.isShowStep = true;
            },

            showCheck : () => {
                pop.cname = add.cname;
                if( !pop.cname.length ){
                    toast.error("고객사명을 입력해주세요.");
                    return;
                }
                pop.isShowCheck = true;
            },

            hideCheck : () => {
                pop.isShowCheck = false;
                console.log(pop.isAble);
            }
        });

        const add = reactive({
            cname    : "",
            ename    : "",
            zipcode  : "",
            addr     : "",
            addr_sub : "",
            ceo_name : "",
            bizno    : "",
            step     : "",
            company_type  : "",
            industry_type : "",
            special_memo  : "",

            doOnlyEng : () => {
                console.log(add.ename);
                // add.ename = add.ename.replace(/[^a-zA-Z ]/gi, '');
                // console.log(add.ename);
            },

            doSubmit : () => {
                let params = {
                    cname    : add.cname,
                    ename    : add.ename,
                    zipcode  : add.zipcode,
                    addr     : add.addr,
                    addr_sub : add.addr_sub,
                    ceo_name : add.ceo_name,
                    bizno    : add.bizno,
                    step     : add.step,
                    company_type  : add.company_type,
                    industry_type : add.industry_type,
                    special_memo  : add.special_memo
                };

                if( !add.cname.length ){
                    toast.error("고객사명을 입력해주세요.");
                    return;
                }
                if( !add.ename.length ){
                  toast.error("고객사 영문명을 입력해주세요.");
                  return;
                }
                if( pop.isAble == "N" || pop.isAble == "" ){
                    toast.error("고객사명 중복확인을 해주세요.");
                    return;
                }
                if( !add.addr_sub ){
                  toast.error("주소를 입력해주세요.");
                  return;
                }
                if( !add.company_type || !add.industry_type || !add.special_memo ){
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                axios.post("/rest/customermgr/addCustomer", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-customerDBList"
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                Swal.fire({
                    title:'등록을 취소하시겠습니까? 입력하신 내용이 사라집니다.',
                    showCancelButton: true
                }).then((res) => {
                    if (res.isConfirmed) {
                        router.go(-1);
                    }
                });
            }
        });

        watch(() => add.cname, (nVal, oVal) => {
            if( nVal != oVal ) {
                add.cname = nVal.replace(/[^ㄱ-ㅎ가-힣]/gi, '');
            }
        });

        watch(() => add.ename, (nVal, oVal) => {
            if( nVal != oVal ) {
                add.ename = nVal.replace(/[^a-zA-Z '&]/gi, '');
            }
        });

        watch(() => add.bizno, (nVal, oVal) => {
            if( nVal != oVal ) {
                let val = nVal.replace(/[^0-9]/gi, '');
                if( val.length <= 3 ){
                    add.bizno = val;
                } else if( 3 < val.length && val.length <= 5 ){
                    add.bizno = val.substr(0, 3) + '-' + val.substr(3, 2);
                } else if( 5 < val.length && val.length <= 10 ){
                    add.bizno = val.substr(0, 3) + '-' + val.substr(3, 2) + '-' + val.substr(5,5);
                } else if( 10 < val.length ) {
                    add.bizno = oVal;
                }
            }
        });

        return {pop, add};
    }
}
</script>

<style lang="scss" scoped>
</style>